import React, { useEffect } from 'react';
import SiteHeader from '../components/SiteHeader';
import Layout from '../components/Layout';
import { navigate } from 'gatsby';
export { default as Head } from '../components/Head';

// import { SEO } from "../components/seo"
const getRedirectLanguage = () => {
  if (typeof navigator === `undefined`) {
    return 'en';
  }
  const lang =
    navigator && navigator.language && navigator.language.split('-')[0];
  if (!lang) return 'en';

  // switch (lang) {
  //   case "ru":
  //     return "ru";
  //   case "ro":
  //       return "ro";
  //   // case "en":
  //   //   return "en";
  //   default:
  //     return "ro";
  // }
  return lang;
};

const introtext = {
  'en': 'Welcome!',
  'es': 'Bienvenido!',
};

function Page({ pageContext }) {
  let urlLang = getRedirectLanguage();
  useEffect(() => {
    if (['en', 'es'].includes(urlLang)) {
      navigate(`/${urlLang}/`, { replace: true });
    } else {
      navigate(`/en/`, { replace: true });
    }
  }, []);
  return (
    <>
    <SiteHeader/>
    <Layout>
      <div>
        <div className="home-content p-6" id="loading">
          <div className="columns is-centered">
            <h2>{introtext[urlLang]}</h2>
          </div>
          <div className="columns is-centered options">
            <a href="/en" className="button">
              English
            </a>
            <a href="/es" className="button">
              Español
            </a>
          </div>
        </div>
      </div>
    </Layout>
    </>
  );
}

export default Page;
